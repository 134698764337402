.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    font-weight: 400;
  }
  
  .spinner {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  
  .loadingText {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  
  .footer {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  
  .poweredBy {
    font-size: 14px;
    color: #4E529A;
    display: flex;
    align-items: center;
  }
  
  .workxplorerIcon {
    height: 20px;
    margin-left: 8px;
    vertical-align: middle;
  }