/* Main Container */
.statisticsDashboard {
  min-height: 100vh;
  color: white;
  padding: 1.5rem;
}

/* Header Section */
.header {
  margin-bottom: 2rem;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.headerButtons {
  display: flex;
  gap: 0.5rem;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  margin: 0;
}

/* Buttons */
.button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s;
  cursor: pointer;
  border: none;
}

.buttonPrimary {
  background-color: rgb(37, 99, 235);
  color: white;
}

.buttonPrimary:hover {
  background-color: rgb(29, 78, 216);
}

.buttonSecondary {
  background-color: rgb(31, 41, 55);
  color: white;
}

.buttonSecondary:hover {
  background-color: rgb(55, 65, 81);
}

/* Summary Cards Grid */
.summaryGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.summaryCard {
  background-color: rgb(31, 41, 55);
  border-radius: 0.5rem;
  padding: 1rem;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.summaryCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.cardHeader {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.cardIcon {
  color: rgb(59, 130, 246);
}

.cardTitle {
  color: rgb(156, 163, 175);
  font-size: 0.875rem;
}

.cardValue {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
}

.cardUnit {
  font-size: 0.875rem;
  margin-left: 0.25rem;
  color: rgb(156, 163, 175);
}

.cardDescription {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: rgb(156, 163, 175);
  line-height: 1.25;
}

/* Distribution Cards */
.distributionsSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.distributionCard {
  background-color: rgb(31, 41, 55);
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.distributionTitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
  margin-bottom: 1rem;
}

.chartAndResponses {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 2rem;
}

.responsesWrapper {
  background-color: rgba(55, 65, 81, 0.5);
  border-radius: 0.5rem;
  padding: 1rem;
}

/* Answer List */
.answerList {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.answerItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
  background-color: rgba(55, 65, 81, 0.3);
  border-radius: 0.375rem;
}

.answerColorIndicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  flex-shrink: 0;
}

.answerText {
  flex: 1;
  font-size: 0.875rem;
  color: rgb(209, 213, 219);
}

.answerCount {
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  white-space: nowrap;
}


/* Loading State */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 16rem;
}

.loadingText {
  font-size: 1.125rem;
  color: rgb(156, 163, 175);
}

/* Error State */
.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: rgba(220, 38, 38, 0.1);
  border: 1px solid rgb(220, 38, 38);
  color: rgb(254, 202, 202);
  border-radius: 0.5rem;
  text-align: center;
}

.retryButton {
  margin-top: 1rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: rgb(220, 38, 38);
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.retryButton:hover {
  background-color: rgb(185, 28, 28);
}

/* Stats Explanation */
.statsExplanation {
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
}

.explanationText {
  font-size: 0.875rem;
  color: rgb(156, 163, 175);
  line-height: 1.5;
}

/* Animations */
.fadeIn {
  animation: fadeIn 0.3s ease-in-out;
}

.completionRate {
  background-color: rgb(31, 41, 55);
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.completionRateText {
  color: white;
  font-size: 1.125rem;
  font-weight: 500;
}

.questionsSection {
  margin-top: 2rem;
}

.sectionTitle {
  font-size: 1.5rem;
  color: white;
  font-weight: 600;
  margin-bottom: 1rem;
}

.questionCard {
  background-color: rgb(31, 41, 55);
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.questionTitle {
  font-size: 1.125rem;
  color: white;
  margin-bottom: 1rem;
  font-weight: 500;
}

.chartWrapper {
  background-color: rgba(55, 65, 81, 0.5);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 1rem 0;
}

.responsesSummary {
  color: rgb(156, 163, 175);
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.tooltip {
  background-color: rgb(31, 41, 55);
  border: 1px solid rgb(55, 65, 81);
  border-radius: 0.5rem;
  padding: 0.75rem;
  color: white;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .chartAndResponses {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .chartWrapper {
    min-height: 250px;
  }

  .summaryGrid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .distributionsSection {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .chartWrapper {
    min-height: 220px;
  }

  .responsesWrapper {
    min-height: auto;
  }

  .headerContent {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .summaryGrid {
    grid-template-columns: 1fr;
  }

  .cardValue {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .statisticsDashboard {
    padding: 1rem;
  }

  .chartWrapper {
    min-height: 200px;
    padding: 0.5rem;
  }

  .responsesWrapper {
    padding: 0.5rem;
  }
  
  .answerText, .answerCount {
    font-size: 0.75rem;
  }
}