.loginContainer {
  background-color: #2D2D3F;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  padding: 40px;
  max-width: 400px;
  width: 90%;
  margin: 50px auto;
}

.loginContainer h2 {
  margin-bottom: 30px;
  font-size: 26px;
  text-align: center;
  color: #FFFFFF;
}

.formGroup {
  margin-bottom: 20px;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  color: #CCCCCC;
  font-size: 14px;
}

.formGroup input {
  width: 100%;
  padding: 12px;
  border: 1px solid #3D3D4F;
  border-radius: 4px;
  background-color: #1E1E2E;
  color: #E0E0E0;
  font-size: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.formGroup input:focus {
  border-color: #4E529A;
  outline: none;
  box-shadow: 0 0 0 2px rgba(78, 82, 154, 0.2);
}

.loginButton {
  width: 100%;
  padding: 12px;
  background-color: #4E529A;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.loginButton:hover {
  background-color: #5A5EAA;
  transform: translateY(-2px);
}

.loginButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.errorMessage {
  color: #FF6B6B;
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
}

@media (max-width: 480px) {
  .loginContainer {
    padding: 30px;
  }

  .loginContainer h2 {
    font-size: 24px;
  }
}