/* Shared container styles */
.questionManagementContainer,
.profileManagementContainer {
  display: flex;
  gap: 20px;
  padding: 20px;
  height: calc(100vh - 180px);
}

/* Side Panel Styles */
.sidePanel {
  width: 300px;
  background-color: #2D2D3F;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.actionButtons {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom: 1px solid #3D3D4F;
}

/* CSV Upload Styles */
.csvUploadContainer {
  position: relative;
  width: 100%;
}

.csvInput {
  display: none;
}

.csvUploadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 12px;
  background-color: #3D3D4F;
  color: #E0E0E0;
  border: 1px dashed #4E529A;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.csvUploadButton:hover {
  background-color: #4E529A;
  border-style: solid;
}

/* List Styles */
.questionsList,
.profilesList {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.questionItem,
.profileItem {
  padding: 12px;
  background-color: #1E1E2E;
  border: 1px solid #3D3D4F;
  border-radius: 6px;
  color: #E0E0E0;
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.questionItem:hover,
.profileItem:hover {
  background-color: #3D3D4F;
}

.questionItem.selected,
.profileItem.selected {
  background-color: #4E529A;
  border-color: #5A5EAA;
}

/* Main Content Styles */
.mainContent {
  flex: 1;
  background-color: #2D2D3F;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
}

/* Language Tabs */
.languageTabs {
  display: flex;
  gap: 10px;
  padding-bottom: 16px;
  border-bottom: 1px solid #3D3D4F;
}

.langTab {
  padding: 8px 16px;
  background-color: #1E1E2E;
  border: 1px solid #3D3D4F;
  border-radius: 6px;
  color: #E0E0E0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.langTab:hover {
  background-color: #3D3D4F;
}

.langTab.active {
  background-color: #4E529A;
  border-color: #5A5EAA;
  color: #FFFFFF;
}

/* Form Styles */
.questionForm,
.profileForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.formGroup label {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
}

.input,
.textarea {
  padding: 12px;
  background-color: #1E1E2E;
  border: 1px solid #3D3D4F;
  border-radius: 6px;
  color: #E0E0E0;
  font-size: 14px;
  width: 100%;
  transition: all 0.2s ease;
}

.textarea {
  min-height: 100px;
  resize: vertical;
}

.input:focus,
.textarea:focus {
  outline: none;
  border-color: #4E529A;
}

/* Variant Section Styles */
.variantItem {
  background-color: #1E1E2E;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #3D3D4F;
  margin-bottom: 12px;
}

.pointsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 12px;
  margin-top: 12px;
}

.pointsInput {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.numberInput {
  padding: 8px;
  background-color: #1E1E2E;
  border: 1px solid #3D3D4F;
  border-radius: 4px;
  color: #E0E0E0;
  width: 100%;
}

/* Button Styles */
.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  background-color: #4E529A;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
}

.addButton:hover {
  background-color: #5A5EAA;
  transform: translateY(-1px);
}

.formActions {
  display: flex;
  gap: 12px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #3D3D4F;
}

.saveButton {
  flex: 1;
  padding: 12px;
  background-color: #4E529A;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.saveButton:hover {
  background-color: #5A5EAA;
  transform: translateY(-1px);
}

.deleteButton {
  padding: 12px;
  background-color: #3D3D4F;
  color: #FF5555;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.deleteButton:hover {
  background-color: rgba(255, 85, 85, 0.1);
  color: #FF7777;
}

/* Profession Section Styles */
.professionsSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.professionItem {
  display: flex;
  gap: 12px;
  align-items: center;
}

.professionItem .input {
  flex: 1;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .questionManagementContainer,
  .profileManagementContainer {
    flex-direction: column;
    height: auto;
  }

  .sidePanel {
    width: 100%;
    max-height: 300px;
  }

  .pointsGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .mainContent {
    padding: 16px;
  }

  .formActions {
    flex-direction: column;
  }

  .languageTabs {
    flex-wrap: wrap;
  }

  .langTab {
    flex: 1;
    text-align: center;
  }

  .professionItem {
    flex-direction: column;
  }
}

/* Scrollbar Styling */
.questionsList::-webkit-scrollbar,
.profilesList::-webkit-scrollbar,
.mainContent::-webkit-scrollbar {
  width: 8px;
}

.questionsList::-webkit-scrollbar-track,
.profilesList::-webkit-scrollbar-track,
.mainContent::-webkit-scrollbar-track {
  background: #1E1E2E;
  border-radius: 4px;
}

.questionsList::-webkit-scrollbar-thumb,
.profilesList::-webkit-scrollbar-thumb,
.mainContent::-webkit-scrollbar-thumb {
  background: #3D3D4F;
  border-radius: 4px;
}

.questionsList::-webkit-scrollbar-thumb:hover,
.profilesList::-webkit-scrollbar-thumb:hover,
.mainContent::-webkit-scrollbar-thumb:hover {
  background: #4E529A;
}