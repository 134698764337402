.adminDashboard {
  min-height: 100vh;
  background-color: #1E1E2E;
  color: #E0E0E0;
}

/* Header Styles */
.header {
  padding: 20px 30px;
  background-color: #2D2D3F;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #3D3D4F;
}

.header h1 {
  font-size: 24px;
  color: #FFFFFF;
  margin: 0;
}

.headerControls {
  display: flex;
  align-items: center;
  gap: 16px;
}

/* Navigation Styles */
.nav {
  display: flex;
  gap: 10px;
}

.navButton {
  background-color: #3D3D4F;
  color: #E0E0E0;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.navButton:hover {
  background-color: #4E529A;
}

.navButton.active {
  background-color: #4E529A;
  color: #FFFFFF;
}

/* Language Selector Styles */
.languageSelector {
  position: relative;
}

.languageButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #3D3D4F;
  border: none;
  border-radius: 6px;
  color: #E0E0E0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.languageButton:hover {
  background-color: #4E529A;
}

.languageDropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  background-color: #2D2D3F;
  border: 1px solid #3D3D4F;
  border-radius: 6px;
  overflow: hidden;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.languageOption {
  display: block;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  color: #E0E0E0;
  cursor: pointer;
  text-align: left;
  transition: all 0.2s ease;
}

.languageOption:hover {
  background-color: #3D3D4F;
}

.languageOption.active {
  background-color: #4E529A;
  color: #FFFFFF;
}

/* Logout Button */
.logoutButton {
  background-color: #4E529A;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.logoutButton:hover {
  background-color: #5A5EAA;
  transform: translateY(-1px);
}

/* Question Management Container */
.questionManagementContainer {
  display: flex;
  gap: 20px;
  padding: 20px;
  height: calc(100vh - 80px);
}

/* Sidebar Styles */
.questionsSidebar {
  width: 280px;
  background-color: #2D2D3F;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.questionsList {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.questionListItem {
  width: 100%;
  padding: 12px;
  background-color: #1E1E2E;
  border: 1px solid #3D3D4F;
  border-radius: 6px;
  color: #E0E0E0;
  text-align: left;
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.questionListItem:hover {
  background-color: #3D3D4F;
}

.questionListItem.active {
  background-color: #4E529A;
  border-color: #5A5EAA;
  color: #FFFFFF;
}

.addQuestionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 10px;
  padding: 12px;
  background-color: #4E529A;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.addQuestionButton:hover {
  background-color: #5A5EAA;
  transform: translateY(-1px);
}

/* Main Content Styles */
.questionContent {
  flex: 1;
  background-color: #2D2D3F;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
}

/* Language Tabs */
.languageTabs {
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #3D3D4F;
}

.languageTab {
  padding: 8px 16px;
  background-color: #1E1E2E;
  border: 1px solid #3D3D4F;
  border-radius: 6px;
  color: #E0E0E0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.languageTab:hover {
  background-color: #3D3D4F;
}

.languageTab.active {
  background-color: #4E529A;
  border-color: #5A5EAA;
  color: #FFFFFF;
}

/* Form Styles */
.questionForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.formSection label {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
}

.formSection input,
.formSection select {
  padding: 12px;
  background-color: #1E1E2E;
  border: 1px solid #3D3D4F;
  border-radius: 6px;
  color: #E0E0E0;
  font-size: 14px;
  transition: all 0.2s ease;
}

.formSection input:focus,
.formSection select:focus {
  outline: none;
  border-color: #4E529A;
}

.formSection input::placeholder {
  color: #6C6C7C;
}

/* Variant Styles */
.variantItem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  background-color: #1E1E2E;
  padding: 16px;
  border-radius: 6px;
  margin-bottom: 12px;
}

.variantItem input {
  width: 95%;
}

.addVariantButton {
  padding: 12px;
  background-color: #3D3D4F;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  width: fit-content;
}

.addVariantButton:hover {
  background-color: #4E529A;
}

/* Action Buttons */
.actionButtons {
  display: flex;
  gap: 12px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #3D3D4F;
}

.saveButton,
.deleteButton {
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.saveButton {
  background-color: #4E529A;
  color: white;
}

.saveButton:hover {
  background-color: #5A5EAA;
  transform: translateY(-1px);
}

.deleteButton {
  background-color: #3D3D4F;
  color: #FF5555;
}

.deleteButton:hover {
  background-color: rgba(255, 85, 85, 0.1);
  color: #FF7777;
}

/* Error Message */
.errorMessage {
  padding: 20px;
  margin: 20px;
  background-color: rgba(255, 85, 85, 0.1);
  border: 1px solid #FF5555;
  border-radius: 6px;
  color: #FF5555;
}

/* Scrollbar Styling */
.questionsList::-webkit-scrollbar,
.questionContent::-webkit-scrollbar {
  width: 8px;
}

.questionsList::-webkit-scrollbar-track,
.questionContent::-webkit-scrollbar-track {
  background: #1E1E2E;
  border-radius: 4px;
}

.questionsList::-webkit-scrollbar-thumb,
.questionContent::-webkit-scrollbar-thumb {
  background: #3D3D4F;
  border-radius: 4px;
}

.questionsList::-webkit-scrollbar-thumb:hover,
.questionContent::-webkit-scrollbar-thumb:hover {
  background: #4E529A;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .questionManagementContainer {
    flex-direction: column;
    height: auto;
  }

  .questionsSidebar {
    width: 100%;
    max-height: 200px;
  }

  .variantItem {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .actionButtons {
    flex-direction: column;
  }

  .saveButton,
  .deleteButton {
    width: 100%;
  }
}

.variantItem {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.variantItem input {
  flex: 1;
}

.uploadQuestions {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
}

.uploadQuestions h2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: var(--text-primary);
}

.uploadButton {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}

.uploadButton:hover {
  background-color: var(--accent-hover);
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: 16px;
    align-items: stretch;
    padding: 16px;
  }

  .nav {
    flex-direction: column;
  }

  .headerControls {
    flex-direction: column;
    width: 100%;
  }

  .languageSelector {
    width: 100%;
  }

  .languageButton {
    width: 100%;
    justify-content: center;
  }

  .logoutButton {
    width: 100%;
  }

  .questionContent {
    padding: 16px;
  }

  .languageTabs {
    flex-wrap: wrap;
  }

  .languageTab {
    flex: 1;
    text-align: center;
  }
}