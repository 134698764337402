/* =====================
   Container & Layout
===================== */
.resultContainer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FAFAFA;
  color: #1F2937;
}

.mainContent {
  width: 100%;
  max-width: 1400px;
  padding: 2rem;
  margin: 0 auto;
  box-sizing: border-box;
  flex: 1;
}

.contentLayout {
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 2rem;
  align-items: start;
}

/* =====================
   Header Styles
===================== */
.header {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
  pointer-events: none;
}

.logoImage {
  height: 40px;
  width: auto;
  object-fit: contain;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
}

.mainTitle {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: white;
  margin: 0;
  padding: 0 1rem;
  line-height: 1.2;
  position: relative;
  z-index: 1;
}

/* =====================
   Column Styles
===================== */
.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.rightColumn {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: fit-content;
  position: sticky;
  top: 2rem;
}

/* =====================
   Section Styles
===================== */
.sectionTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1F2937;
  margin-bottom: 1.5rem;
}

/* Profile Scores */
.profileScoresGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
}

.profileScoreCard {
  padding: 1rem 1.5rem;
  border-radius: 9999px;
  color: white;
  display: flex;
  align-items: center;
  transition: transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 56px;
}

.profileScoreCard:hover {
  transform: translateX(5px);
}

.profileScoreText {
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Chart Section */
.chartContainer {
  height: 350px;
  margin-bottom: 1.5rem;
}

.profileDescription {
  color: #4B5563;
  font-size: 0.875rem;
  line-height: 1.6;
}

/* Professions Section */
.professionsSection {
  margin-top: 0;
}

.professionsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.professionCard {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  display: flex;
  flex-direction: column;
}

.buttonGroup {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.professionButton {
  flex: 1;
  padding: 0.75rem;
  border-radius: 100px;
  font-size: 0.875rem;
  cursor: pointer;
  text-align: center;
  transition: opacity 0.3s ease;
  border: none;
  font-family: inherit;
}

.primaryButton {
  background: #011835;
  color: white;
}

.primaryButton:hover {
  opacity: 0.9;
}

.secondaryButton {
  background: transparent;
  border: 1px solid #011835;
  color: #011835;
}

.secondaryButton:hover {
  background: rgba(1, 24, 53, 0.05);
}

.professionCard:hover {
  transform: translateY(-2px);
}

.professionName {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1F2937;
  margin-bottom: 1rem;
}

.salaryInfo {
  font-size: 0.875rem;
  color: #4B5563;
  padding-top: 1rem;
  border-top: 1px solid #E5E7EB;
}

/* Footer */
.footer {
  width: 100%;
  padding: 8px 0;
  margin-top: auto;
  flex-shrink: 0;
}

.poweredBy {
  font-size: 14px;
  font-weight: 700;
  color: #4E529A;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workxplorerIcon {
  height: 20px;
  margin-left: 8px;
}

/* =====================
   Responsive Styles
===================== */
@media (max-width: 1280px) {
  .mainContent {
    padding: 1.5rem;
  }
  
  .professionsGrid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 1024px) {
  .contentLayout {
    grid-template-columns: 1fr;
    display: flex;
    flex-direction: column;
  }

  .leftColumn {
    order: 2;
  }

  .rightColumn {
    position: static;
    order: 1;
    margin-bottom: 2rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .mainContent {
    padding: 1rem;
  }

  .contentLayout {
    gap: 1rem;
  }

  .profileScoresGrid {
    grid-template-columns: minmax(auto, 400px);
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .profileScoreCard {
    width: 100%;
    box-sizing: border-box;
  }

  .professionsGrid {
    grid-template-columns: 1fr;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .professionCard {
    max-width: 100%;
  }

  .chartContainer {
    height: 300px;
  }

  .mainTitle {
    font-size: 1.25rem;
  }

  .sectionTitle {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 1rem;
  }

  .mainContent {
    padding: 1rem 0;
  }

  .profileScoreCard {
    padding: 0.75rem 1rem;
  }

  .profileScoreText {
    font-size: 0.875rem;
  }

  .professionCard {
    padding: 1.25rem;
  }

  .rightColumn,
  .profileScoresGrid,
  .professionsGrid {
    width: 85%;
  }

  .sectionTitle {
    width: 85%;
  }
}

/* =====================
   RTL Support
===================== */
[dir="rtl"] .profileScoreCard:hover {
  transform: translateX(-5px);
}

[dir="rtl"] .workxplorerIcon {
  margin-left: 0;
  margin-right: 8px;
}

/* =====================
   Accessibility
===================== */
@media (prefers-reduced-motion: reduce) {
  .profileScoreCard,
  .professionCard {
    transition: none;
  }
}

/* =====================
   Print Styles
===================== */
@media print {
  .resultContainer {
    background: white;
  }

  .header {
    background: none !important;
    color: #1F2937;
  }

  .mainTitle {
    color: #1F2937;
  }

  .profileScoreCard {
    border: 1px solid #E5E7EB;
    color: #1F2937;
    background: none !important;
  }

  .rightColumn,
  .professionCard {
    box-shadow: none;
    border: 1px solid #E5E7EB;
  }
}