/* UniversityQuiz.module.css */
.quizContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 20px 20px 0;
  box-sizing: border-box;
  font-weight: 400;
  justify-content: flex-start;
}

/* Header */
.header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  position: relative;
}

.logo {
  height: 40px;
  width: auto;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.questionCount {
  font-size: 14px;
  white-space: nowrap;
  font-weight: bold;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
  margin-left: auto;
}

.progressWrapper {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  justify-self: center;
}

.progressBar {
  width: 100%;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  overflow: hidden;
}

.progressFill {
  height: 100%;
  border-radius: 4px;
  transition: width 0.5s ease;
}

/* Quiz Content */
.quizContent {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.questionText {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.answerOptions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 0;
  flex: 1 0 auto;
}

.answerButton {
  background-color: transparent;
  border: 2px solid;
  border-radius: 8px;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: left;
  font-weight: 500;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
}

.answerButton:nth-child(1) { animation-delay: 0.1s; }
.answerButton:nth-child(2) { animation-delay: 0.2s; }
.answerButton:nth-child(3) { animation-delay: 0.3s; }
.answerButton:nth-child(4) { animation-delay: 0.4s; }


.answerButton:hover {
  background-color: currentColor;
}

.answerButton:hover span {
  color: white;
}

/* Back Button */
.backButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 20px;
  border: 2px solid;
  border-radius: 25px;
  background-color: white;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  margin: 0 auto;
  width: fit-content;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards 0.5s;
}

.backButton {animation-delay: 0.5s;}

.backButton:hover {
  opacity: 0.9;
}

.backButton:hover span {
  color: white;
}

/* Form Elements */
.userInfoForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  align-items: center;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.input {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.input:focus {
  outline: none;
  border-color: currentColor;
}

.submitButton {
  width: 100%;
  max-width: 400px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.submitButton:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.submitButton:active {
  transform: translateY(0);
}

/* Footer */
.footer {
  width: 100%;
  padding: 8px 0;
  margin-top: auto;
  flex-shrink: 0;
}

.poweredBy {
  font-size: 14px;
  font-weight: 700;
  color: #4E529A;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workxplorerIcon {
  height: 20px;
  margin-left: 8px;
}

/* RTL Support */
[dir="rtl"] .workxplorerIcon {
  margin-left: 0;
  margin-right: 8px;
}

.errorText {
  color: #ff6b6b;
  font-size: 14px;
  text-align: center;
  animation: shake 0.5s ease;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}

/* Media Queries */
@media (max-width: 1024px) {
  .header {
    grid-template-columns: 1fr;
    gap: 15px;
    text-align: center;
  }

  .logo {
    justify-self: center;
  }

  .questionCount {
    margin-left: 0;
    justify-self: center;
  }

  .progressWrapper {
    max-width: 300px;
    margin: 10px auto;
  }

  .backButton {
    bottom: 70px;
    right: 15px;
    padding: 10px 16px;
    font-size: 14px;
  }
}

/* QuizWrapper styles */
.quizWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f8fafc;
  position: relative;
  justify-content: flex-start;
}

.quizWrapper > * {
  flex-shrink: 0;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  text-align: center;
  color: rgb(141, 0, 0);
  min-height: 50vh;
}

.retryButton {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: rgb(37, 99, 235);
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: background-color 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.retryButton:hover {
  background-color: rgb(29, 78, 216);
}

.retryButton:active {
  transform: translateY(1px);
}

.retryButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.4);
}

/* Additional Media Queries */
@media (max-width: 768px) {
  .quizWrapper {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .error {
    padding: 1.5rem 1rem;
  }

  .retryButton {
    width: 100%;
    max-width: 300px;
    padding: 0.875rem 1rem;
  }
}

/* Mobile Browser Fixes */
@supports (-webkit-touch-callout: none) {
  .quizContainer {
    min-height: -webkit-fill-available;
  }
  
  .quizWrapper {
    height: -webkit-fill-available;
  }
}

/* Firefox Fix */
@-moz-document url-prefix() {
  .quizWrapper {
    min-height: 100vh;
  }
}

/* RTL Support */
[dir="rtl"] .quizWrapper {
  direction: rtl;
}

/* Print styles */
@media print {
  .quizWrapper {
    background-color: white;
    min-height: auto;
  }

  .retryButton {
    display: none;
  }
}

/* iOS Input Zoom Fix */
@media screen and (max-width: 768px) {
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  select {
    font-size: 16px !important;
  }
}

/* Scrollbar Fix */
html {
  width: 100vw;
  overflow-x: hidden;
}