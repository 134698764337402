/* InstructionsModal.module.css */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: white;
    padding: 2rem;
    border-radius: 20px;
    position: relative;
    width: 90%;
    max-width: 500px;
  }
  
  .closeButton {
    position: absolute;
    right: 1rem;
    top: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #666;
  }
  
  .modalTitle {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #011835;
    font-weight: 500;
  }
  
  .modalSubtitle {
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #011835;
  }
  
  .instructionsList {
    margin-bottom: 2rem;
  }
  
  .instructionItem {
    margin-bottom: 1rem;
    color: #4A5568;
    line-height: 1.5;
  }
  
  .readyButton {
    width: 100%;
    padding: 1rem;
    background: #011835;
    color: white;
    border: none;
    border-radius: 100px;
    font-size: 1rem;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  
  .readyButton:hover {
    opacity: 0.9;
  }
  
  /* Mobile Styles */
  @media (max-width: 480px) {
    .modalContent {
      padding: 1.5rem;
      width: 75%;
    }
  
    .modalTitle {
      font-size: 1.25rem;
    }
  
    .modalSubtitle {
      font-size: 1rem;
    }
  
    .instructionItem {
      font-size: 0.9rem;
    }
  }